import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Blurbs from '../components/blurb';
import Banner from '../components/banner'

export const query = graphql`
  query ($id: String!) {
    markdownRemark( id: { eq: $id } ) {

        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          heading
          subheading
          mainpitch {
            title
            description
          }
          description
          intro {
            blurbs {
              image {
                childImageSharp {
                  fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
              pageLink
              heading
              subHeading
              text
              list
            }
            
          }
        }
    }
  }
`

export const PageTemplate = ({
  image,
  alt,
  heading,
  subheading,
  mainpitch,
  intro
}) => {
      return (
      
      <>
        <Banner 
          fluid={image.childImageSharp.fluid} 
          alt={alt}
          heading={heading}
          subheading={subheading}
          />
        <div className="container marketing">
          <div className="row mt-5">
              <h2 className="text-center">
                {mainpitch.title}
              </h2>
              <br/>
              <p className="lead">
                {mainpitch.description}
              </p>
          </div>

          <Blurbs blurbs={intro.blurbs}/>

          <hr className="featurette-divider"/>

        </div>
      </>
    );
  }

PageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  }),
}

const Page = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SEO 
      title={frontmatter.title}
      description={frontmatter.description}
        />
      <PageTemplate
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default Page


